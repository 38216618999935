import { srcOrigin } from '@/globals/env'
import { SUPPORTED_POSHMARK_URL_REGEX_ARR } from '@/globals/variables'

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const log =
  import.meta.env.VITE_APP_DEBUG === 'true'
    ? console.log.bind(window.console)
    : () => {
        /* do nothing */
      }

export const error =
  import.meta.env.VITE_APP_DEBUG === 'true'
    ? console.error.bind(window.console)
    : () => {
        /* do nothing */
      }

export const calcRandomTimer = (delay: { min: number; max: number }) => {
  return Math.floor(Math.random() * (delay.max - delay.min + 1) + delay.min)
}

export const formatDate = (date: Date) => {
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit'
  }).format(date)

  return formattedDate
}

export const isValidDate = (dateString: string) => {
  return !isNaN(Date.parse(dateString))
}

export const scrollToTop = () => {
  document
    .querySelector('#poshpop-extension-app-container')
    ?.scrollTo({ top: 0, behavior: 'smooth' })
}

export const isValidUrl = (str: string) => {
  try {
    const url = new URL(str)
    return url.protocol === 'http:' || url.protocol === 'https:'
  } catch (err) {
    return false
  }
}

export const isValidClosetUrl = (str: string) => {
  try {
    const url = new URL(str)

    if (
      !url.hostname ||
      !(url.hostname.includes('poshmark.com') || url.hostname.includes('poshmark.ca'))
    )
      return false

    if (!url.pathname || !url.pathname.includes('closet')) return false

    return url.protocol === 'http:' || url.protocol === 'https:'
  } catch (err) {
    return false
  }
}

export const sendMessage = (msg: any) => {
  window.parent.postMessage(msg, srcOrigin)
}

export const handleServerError = (error: any) => {
  if (typeof error === 'object') {
    Object.values(error).forEach((err) => {
      if (typeof err === 'string') {
        throw new Error(err)
      } else if (Array.isArray(err)) {
        throw new Error(...err)
      }
    })
  } else if (typeof error === 'string') {
    throw new Error(error)
  }
}

export const isSupportedSite = (url: string) => {
  for (const regex of SUPPORTED_POSHMARK_URL_REGEX_ARR) {
    if (url.match(regex)) return true
  }
  return true
}
