<template>
    <TabNavigation :is-active="taskStatus.unfollow.status" :is-scheduled="taskStatus.unfollow.daily" />
    <LoadingScreen :isLoading="isLoading" />
    <div class="p-0 h-full w-full z-[9999999] overflow-y-auto overflow-x-hidden" v-show="!hideUI">
        <Card class="p-0">
            <template #title>
                <div class="flex items-center justify-between">
                    <h6 class="text-base">UnFollow</h6>
                </div>
            </template>
            <template #content>
                <div v-if="validations.has('unfollow')">
                    <Message severity="error" v-for="(errorMessage, index) of validations.get('unfollow')"
                        :keys="errorMessage" icon="null" :closable="false">
                        {{ errorMessage }}
                    </Message>
                </div>
                <div class="py-3">
                    <h6 class="text-base">Time Delay</h6>
                    <div class="flex justify-between w-full ">
                        <div class="flex items-center mb-2">
                            <InputNumber v-model="delayRange[0]" :min="0" :max="delayRange[1] - 1"
                                inputClass="!w-[30px] text-center" :disabled="taskStatus.unfollow.status" />
                            <span>Sec</span>
                        </div>
                        <div class="flex items-center mb-2">
                            <InputNumber v-model="delayRange[1]" :min="delayRange[0] + 1" :max="60"
                                inputClass="!w-[30px] text-center" :disabled="taskStatus.unfollow.status" />
                            <span>Sec</span>
                        </div>
                    </div>
                    <Slider v-on:update:model-value="updateSliderValue" :pt="sliderStyles" v-model="delayRange" range
                        :step="1" ariaLabel="sec" :min="1" :max="50" class="mx-2" :disabled="taskStatus.unfollow.status" />
                </div>
                <div class="flex items-center gap-3 mt-2">
                    <label for="unfollowLimit">
                        UnFollow
                    </label>
                    <span>
                        <InputNumber placeholder="0" :input-class="'w-[100px] p-3 border border-[#0070b7] rounded-lg '"
                            v-model="unfollowLimit" inputId="unfollowLimit" :useGrouping="true" :max=1000
                            :disabled="taskStatus.unfollow.status" />
                    </span>
                </div>
                <div class="flex items-center mt-2">
                    <InputSwitch input-id="unfollowRepeatDaily" v-model="unfollowRepeatDaily" onLabel="ON" offLabel="OFF"
                        class="w-2rem me-2" :disabled="taskStatus.unfollow.status" />
                    <label :for="'unfollowRepeatDaily'" class="hover:cursor-pointer">
                        Repeat Daily
                    </label>
                </div>
                <div class="flex justify-end p-1">
                    <div class="flex justify-end mt-5 gap-x-4">
                        <Button v-if="taskStatus.unfollow.status" @click="requireConfirmation('top')"
                            class="border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500">
                            Stop Current Task
                        </Button>
                        <Button v-else
                            class="border border-[#0070b7] font-bold bg-[#0070b7] text-white p-4 rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7]"
                            @click="validateUnfollowRequest()">
                            Start
                            <div v-if="!authStore.hasActivePlan()"
                                class="bg-white rounded-full w-5 h-5 flex items-center justify-center ml-2">
                                <i class="pi pi-dollar text-[#0070b7] font-normal text-sm"></i>
                            </div>
                        </Button>
                    </div>
                </div>
            </template>
        </Card>

        <ConfirmDialog group="positioned"></ConfirmDialog>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useTaskStatusStore } from '@/stores/taskStatus';
import { useConfirm } from 'primevue/useconfirm';
import { log, scrollToTop } from "@/utils/index";
import { useAuthStore } from "../stores/auth";
import { useActiveTab } from '@/stores/activeTab';
import TabNavigation from "./TabNavigation.vue"
import InputText from 'primevue/inputtext';
import Button from "primevue/button";
import InputNumber from "primevue/inputnumber";
import InputSwitch from 'primevue/inputswitch';
import Card from 'primevue/card';
import Slider from "primevue/slider";
import TaskType from "../enums/TaskType";
import ConfirmDialog from 'primevue/confirmdialog';
import Message from 'primevue/message';
import * as Sentry from '@sentry/vue';
import LocalStorageWrapper from '@/classes/LocalStorageWrapper';
import LoadingScreen from '@/components/LoadingScreen.vue';

const isLoading = ref(false);
const hideUI = ref(true)
const localStorageWrapper = LocalStorageWrapper.getInstance()
const authStore = useAuthStore();
const tabView = useActiveTab();
const confirm = useConfirm();
const taskStatus = useTaskStatusStore();
const unfollowRepeatDaily = ref<boolean>(false);
const isUnfollowEnabled = ref<boolean>(taskStatus.unfollow.status)
const unfollowLimit = ref<number>(0)
const delayRange = ref<number[]>([3, 7]);
const validations = ref(new Map())

//PrimeVue Components Styles
const sliderStyles = {
    startHandler: { class: 'bg-[#0070b7]' },
    endHandler: { class: 'bg-[#0070b7]' },
    range: { class: 'bg-[#0070b7]' }
}

onMounted(async () => {
    //load last used settings if found
    await loadUnfollowSettings();

    hideUI.value = false
    setTimeout(() => {
        isLoading.value = false
    }, 300)
})

const onUnfollowChange = (event: Event) => {

    const target = event.target as HTMLInputElement;

    if (target?.checked) {
        //load last used settings if found
        loadUnfollowSettings();
    }

    //reset error messages
    validations.value.set("unfollow", []);
}

const loadUnfollowSettings = async () => {
    try {
        const storedData = await localStorageWrapper.get('unfollowSettings')
        const unfollowSettings = JSON.parse(storedData as string)

        if (!unfollowSettings) return

        unfollowLimit.value = unfollowSettings.limit
        delayRange.value = unfollowSettings.delayRange ?? [3, 7]
        unfollowRepeatDaily.value = !!unfollowSettings.repeat
    } catch (err) {
        //failed to acccess localStorage
        Sentry.captureException(err);
    }

}

const updateSliderValue = (val: number[]) => {
    val[0] = Math.floor(val[0])
    val[1] = Math.floor(val[1])
}


const sendMessage = (msg: { message: string, type?: string, limit?: number, delay?: { min: number, max: number }, newlyJoinedClosetLimit?: number, reverse?: string, closetURL?: string, scanClosetURL?: string, sendOfferAfter?: number, percentDiscount?: number, shippingOffer?: string, excludeStatus?: any, exemptInventoryList?: string[], listedLately?: number, listingsOlderThan?: number, priceRange?: number[], increase?: boolean, scanOlderThan?: number, productID?: string, link?: string, taskType?: TaskType, time?: string, repeat?: boolean }) => {
    window.parent.postMessage(msg, "*");
}

const validateUnfollowRequest = () => {

    if (!authStore.hasActivePlan()) {
        upgradeSubscriptionAlert('top')
        return
    }

    if (taskStatus.unfollow.status) return

    log("Validating Unfollow request")

    let messages = []

    if (!delayRange.value[0] || delayRange.value[0] < 0)
        messages.push("Delay should be at least 1 second.")
    else if (!delayRange.value[1] || delayRange.value[1] < delayRange.value[0])
        messages.push("Invalid delay range.");

    if (!unfollowLimit.value || unfollowLimit.value <= 0)
        messages.push("Unfollow limit must be greater than 0");
    if (unfollowLimit.value > 1000)
        messages.push("A maximum of 1000 limit is required.");


    validations.value.set("unfollow", messages);

    if (messages.length) {
        scrollToTop()
        return
    }

    //update and send to extension to start task
    const taskData = { message: 'loadFollowers', type: 'unfollow', limit: unfollowLimit.value, delay: { min: delayRange.value[0], max: delayRange.value[1] }, repeat: unfollowRepeatDaily.value }

    //start message listener
    window.addEventListener("message", unfollowMessagesHandler);

    sendMessage(taskData)

    taskStatus.changeUnfollowStatus(true);
    taskStatus.changeUnfollowDaily(unfollowRepeatDaily.value);

    //storing current task configuration to load later to the user
    const config = {
        ...taskData,
        delayRange: delayRange.value,

    }

    //store latest settings in local storage
    localStorageWrapper.set('unfollowSettings', JSON.stringify(config))

}

const requireConfirmation = (position: any) => {
    confirm.require({
        group: 'positioned',
        icon: 'pi pi-info-circle',
        position: position,
        rejectClass: 'border border-green-500 font-bold px-8 py-1 bg-green-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-green-500',
        acceptClass: 'border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500 outline-0 shadow-none',
        rejectLabel: 'Keep Task running',
        acceptLabel: 'Stop Task',
        header: 'Are you sure you want to stop this task?',
        message: 'Please confirm to proceed.',
        accept: () => {
            sendMessage({ message: 'stopUnfollowTask' });
        }
    });
};

const upgradeSubscriptionAlert = (position: any) => {
    confirm.require({
        group: 'positioned',
        icon: 'pi pi-info-circle',
        position: position,
        acceptClass: 'border border-green-500 font-bold px-8 py-1 bg-green-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-green-500',
        rejectClass: 'border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500 outline-0 shadow-none',
        rejectLabel: 'Cancel',
        acceptLabel: 'Subscribe',
        header: 'Upgrade Subscription',
        message: 'Upgrade subscription to enable this feature.',
        accept: () => {
            tabView.changeActiveTab(2)
        }
    });
};

//This will handle messages sent from the extension to the web server
const unfollowMessagesHandler = (event: any) => {
    if (event.data.message == "unfollowTaskEnded") {

        //the stop request was send by the user or if the follow is completed and it is not scheduled to run daily
        if (event.data.requestedByUser || !event.data.isDaily) {
            taskStatus.changeUnfollowStatus(false)
            taskStatus.changeUnfollowDaily(false)
            //stop message listener until the task is started again
            window.removeEventListener("message", unfollowMessagesHandler);
            return
        }
    }
    else if (event.data.message == "failedToStartUnfollowTask") {

        //validations.value.set("unfollow", [event.data.error]);
        taskStatus.changeUnfollowStatus(false);
        taskStatus.changeUnfollowDaily(false);
        //stop message listener until the task is started again
        window.removeEventListener("message", unfollowMessagesHandler);
    }
}

</script>

