import { serverUrl } from '@/globals/env'
import { useAuthStore } from '@/stores/auth'
import * as Sentry from '@sentry/vue'

export const loginApi = async (data: { email: string; password: string }) => {
  try {
    const response = await fetch(serverUrl + 'login', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify({ email: data.email, password: data.password })
    })

    const status = response.status
    const json = await response.json()
    return { status, json }
  } catch (err) {
    Sentry.captureException(err)
    throw new Error(`Failed to login: ${err}`)
  }
}

export const registerApi = async (data: {
  name: string
  email: string
  password: string
  password_confirmation: string
}) => {
  try {
    const response = await fetch(serverUrl + 'register', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: data.name,
        email: data.email,
        password: data.password,
        password_confirmation: data.password_confirmation
      })
    })

    const status = response.status
    const json = await response.json()
    return { status, json }
  } catch (err) {
    Sentry.captureException(err)
    throw new Error(`Failed to register: ${err}`)
  }
}

export const logoutApi = async () => {
  try {
    const authStore = useAuthStore()
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        Authorization: 'Bearer ' + authStore.user.token
      }
    }

    let response = await fetch(serverUrl + 'logout', options)

    //refresh token if it has expired
    if (response.status === 419) {
      await authStore.refreshToken()
      options.headers['Authorization'] = 'Bearer ' + authStore.user.token
      response = await fetch(serverUrl + 'logout', options)
    } else if (response.status === 401) {
      //logout user and return
      await authStore.destroyUserData()
      return { status: response.status, json: { message: 'invalid session' } }
    }

    const status = response.status
    const json = await response.json()
    return { status, json }
  } catch (err) {
    Sentry.captureException(err)
    throw new Error(`Failed to end session: ${err}`)
  }
}

export const createReferralCodeAPI = async () => {
  const authStore = useAuthStore()
  try {
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        Authorization: 'Bearer ' + authStore.user.token
      }
    }

    let response = await fetch(serverUrl + 'generate-referrer-code', options)

    //refresh token if it has expired
    if (response.status === 419) {
      await authStore.refreshToken()
      options.headers['Authorization'] = 'Bearer ' + authStore.user.token
      response = await fetch(serverUrl + 'generate-referrer-code', options)
    } else if (response.status === 401) {
      //logout user and return
      await authStore.destroyUserData()
      return { status: response.status, json: { message: 'invalid session' } }
    }

    const status = response.status
    const json = await response.json()
    return { status, json }
  } catch (err) {
    Sentry.captureException(err)
    throw new Error(`Failed to create referral code: ${err}`)
  }
}

export const syncSession = async () => {
  const authStore = useAuthStore()

  try {
    if (!authStore.user?.token) return { status: '', json: {} }

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        Authorization: 'Bearer ' + authStore.user.token
      }
    }

    const response = await fetch(serverUrl + 'syncSession', options)

    const status = response.status
    const json = await response.json()

    //refresh token if it has expired
    if (response.status == 200) {
      await authStore.updateUserData(json)
    } else if (response.status === 419) {
      await authStore.refreshToken()
    } else {
      //logout user and return
      await authStore.destroyUserData()
      return { status: response.status, json: { message: 'invalid session' } }
    }

    return { status, json }
  } catch (error) {
    Sentry.captureException(error)
    authStore.destroyUserData()
  }
}
