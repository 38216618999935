<template>
    <div class="grid grid-cols-1 gap-3">
        <!-- <button @click="solveCaptcha()">test captcha</button> -->
        <Card :class="['p-0 mt-2 hover:cursor-pointer']" @click="$router.replace({ name: 'share' })">
            <template #content>
                <div class="flex items-center justify-between">
                    <div class="flex gap-x-2 items-center justify-between w-[40%]">
                        <h6>Share</h6>
                        <Tag v-if="taskStatus.share.status" severity="success" value="Active"></Tag>
                        <Tag v-else severity="secondary" value="Inactive"></Tag>
                    </div>
                    <Button v-if="!taskStatus.share.status"
                        class="border border-[#0070b7] font-bold px-8 py-1 bg-[#0070b7] text-white rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7]">
                        Start
                    </Button>
                    <Button v-else
                        class="border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500">
                        Stop
                    </Button>
                </div>
            </template>
        </Card>

        <Card :class="cardClasses" @click="$router.replace({ name: 'follow' })">
            <template #content>
                <div class="flex items-center justify-between">
                    <div class="flex gap-x-2 items-center justify-between w-[40%]">
                        <h6>Follow</h6>
                        <Tag v-if="taskStatus.follow.status || taskStatus.followJustJoined.status || taskStatus.followByUsername.status"
                            severity="success" value="Active"></Tag>
                        <Tag v-else severity="secondary" value="Inactive"></Tag>
                    </div>
                    <Button
                        v-if="!(taskStatus.follow.status || taskStatus.followJustJoined.status || taskStatus.followByUsername.status)"
                        class="border border-[#0070b7] font-bold px-8 py-1 bg-[#0070b7] text-white rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7]">
                        <span v-if="authStore.hasActivePlan()">Start</span>
                        <div v-else class="flex items-center gap-x-2">
                            Start
                            <div class="bg-white rounded-full w-5 h-5 flex items-center justify-center">
                                <i class="pi pi-dollar text-[#0070b7] font-normal text-sm"></i>
                            </div>
                        </div>
                    </Button>
                    <Button v-else
                        class="border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500">
                        Stop
                    </Button>
                </div>
            </template>
        </Card>

        <Card :class="cardClasses" @click="$router.replace({ name: 'unfollow' })">
            <template #content>
                <div class="flex items-center justify-between">
                    <div class="flex gap-x-2 items-center justify-between w-[40%]">
                        <h6>UnFollow</h6>
                        <Tag v-if="taskStatus.unfollow.status" severity="success" value="Active"></Tag>
                        <Tag v-else severity="secondary" value="Inactive"></Tag>
                    </div>
                    <Button v-if="!taskStatus.unfollow.status"
                        class="border border-[#0070b7] font-bold px-8 py-1 bg-[#0070b7] text-white rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7]">
                        <span v-if="authStore.hasActivePlan()">Start</span>
                        <div v-else class="flex items-center gap-x-2">
                            Start
                            <div class="bg-white rounded-full w-5 h-5 flex items-center justify-center">
                                <i class="pi pi-dollar text-[#0070b7] font-normal text-sm"></i>
                            </div>
                        </div>
                    </Button>
                    <Button v-else
                        class="border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500">
                        Stop
                    </Button>
                </div>
            </template>
        </Card>

        <Card :class="cardClasses" @click="$router.replace({ name: 'offers' })">
            <template #content>
                <div class="flex items-center justify-between">
                    <div class="flex gap-x-2 items-center justify-between w-[40%] justify-between w-[40%]">
                        <h6>Offers</h6>
                        <Tag v-if="taskStatus.autoOffer.status || taskStatus.bulkOffer.status" severity="success"
                            value="Active"></Tag>
                        <Tag v-else severity="secondary" value="Inactive"></Tag>
                    </div>
                    <Button v-if="!(taskStatus.autoOffer.status || taskStatus.bulkOffer.status)"
                        class="border border-[#0070b7] font-bold px-8 py-1 bg-[#0070b7] text-white rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7]">
                        <span v-if="authStore.hasActivePlan()">Start</span>
                        <div v-else class="flex items-center gap-x-2">
                            Start
                            <div class="bg-white rounded-full w-5 h-5 flex items-center justify-center">
                                <i class="pi pi-dollar text-[#0070b7] font-normal text-sm"></i>
                            </div>
                        </div>
                    </Button>
                    <Button v-else
                        class="border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500">
                        Stop
                    </Button>
                </div>
            </template>
        </Card>

        <Card :class="cardClasses" @click="$router.replace({ name: 'bulkPrice' })">
            <template #content>
                <div class="flex items-center justify-between">
                    <div class="flex gap-x-2 items-center justify-between w-[40%]">
                        <h6>Bulk Price</h6>
                        <Tag v-if="taskStatus.bulkPrice.status" severity="success" value="Active"></Tag>
                        <Tag v-else severity="secondary" value="Inactive"></Tag>
                    </div>
                    <Button v-if="!taskStatus.bulkPrice.status"
                        class="border border-[#0070b7] font-bold px-8 py-1 bg-[#0070b7] text-white rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7]">
                        <span v-if="authStore.hasActivePlan()">Start</span>
                        <div v-else class="flex items-center gap-x-2">
                            Start
                            <div class="bg-white rounded-full w-5 h-5 flex items-center justify-center">
                                <i class="pi pi-dollar text-[#0070b7] font-normal text-sm"></i>
                            </div>
                        </div>
                    </Button>
                    <Button v-else
                        class="border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500">
                        Stop
                    </Button>
                </div>
            </template>
        </Card>

        <Card :class="cardClasses" @click="$router.replace({ name: 'closet-scanner' })">
            <template #content>
                <div class="flex items-center justify-between">
                    <div class="flex gap-x-2 items-center justify-between w-[40%] justify-between w-[40%]">
                        <h6 class="flex items-center">Scan Closet</h6>
                        <div title="This feature runs instantly and does not get scheduled"><i
                                class="pi pi-bolt text-blue-500 border-blue-500 border-2 p-[1px] rounded-full"></i>
                        </div>
                        <Tag v-if="taskStatus.scanCloset.status" severity="success" value="Active"></Tag>
                        <Tag v-else severity="secondary" value="Inactive"></Tag>
                    </div>
                    <Button v-if="!taskStatus.scanCloset.status"
                        class="border border-[#0070b7] font-bold px-8 py-1 bg-[#0070b7] text-white rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7]">
                        <span v-if="authStore.hasActivePlan()">Start</span>
                        <div v-else class="flex items-center gap-x-2">
                            Start
                            <div class="bg-white rounded-full w-5 h-5 flex items-center justify-center">
                                <i class="pi pi-dollar text-[#0070b7] font-normal text-sm"></i>
                            </div>
                        </div>
                    </Button>
                    <Button v-else
                        class="border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500">
                        Stop
                    </Button>
                </div>
            </template>
        </Card>

        <Card :class="cardClasses" @click="$router.replace({ name: 'closet-calculator' })">
            <template #content>
                <div class="flex items-center justify-between">
                    <div class="flex gap-x-2 items-center justify-between w-[40%] justify-between w-[40%]">
                        <h6>Closet Calculator</h6>
                        <div title="This feature runs instantly and does not get scheduled"><i
                                class="pi pi-bolt text-blue-500 border-blue-500 border-2 p-[1px] rounded-full"></i>
                        </div>
                        <Tag v-if="taskStatus.calculateCloset.status" severity="success" value="Active"></Tag>
                        <Tag v-else severity="secondary" value="Inactive"></Tag>
                    </div>
                    <Button v-if="!(taskStatus.calculateCloset.status)"
                        class="border border-[#0070b7] font-bold px-8 py-1 bg-[#0070b7] text-white rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7]">
                        <span v-if="authStore.hasActivePlan()">Start</span>
                        <div v-else class="flex items-center gap-x-2">
                            Start
                            <div class="bg-white rounded-full w-5 h-5 flex items-center justify-center">
                                <i class="pi pi-dollar text-[#0070b7] font-normal text-sm"></i>
                            </div>
                        </div>
                    </Button>
                    <Button v-else
                        class="border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500">
                        Stop
                    </Button>
                </div>
            </template>
        </Card>
        <ConfirmDialog group="positioned"></ConfirmDialog>
    </div>
</template>

<script setup lang="ts">
import Button from 'primevue/button';
import Card from 'primevue/card';
import Tag from 'primevue/tag';
import ConfirmDialog from "primevue/confirmdialog";
import { useAuthStore } from '../stores/auth';
import { useTaskStatusStore } from '../stores/taskStatus';
import { log } from "@/utils/index"

const authStore = useAuthStore();
const taskStatus = useTaskStatusStore();


//PrimeVue Components Styles
const cardClasses = ['p-0 hover:cursor-pointer', { 'hover:cursor-not-allowed': !authStore.hasActivePlan() }]

const solveCaptcha = () => {
    log("Solving captcha...");
    window.parent.postMessage({ message: "solveCaptcha" }, "*");
}
</script>